<template>
  <div class="flex-col page">
    <div class="flex-col header">
      <div class="justify-between group_2">
        <van-nav-bar :placeholder="true" :left-arrow="true" :fixed="true" title="申请免费使用" style="width: 100%"
          @click-left="onClickLeft" />
      </div>
    </div>
    <div class="flex-col group_4">
      <div class="flex-col section_1">
        <template v-if="statusNum == 3">
          <img src="@/assets/img/success.png" class="image_5" />
          <div class="flex-col items-center group_5">
            <span class="text_2">申请成功</span>
            <span class="text_3">恭喜您，您的试用申请已通过</span>
          </div>
        </template>
        <template v-if="statusNum == 1">
          <img src="@/assets/img/review.png" class="image_5" />
          <div class="flex-col items-center group_5">
            <span class="text_2">审核中</span>
            <span class="text_3">我们将在2个工作日内完成审核，请耐心等待</span>
          </div>
        </template>
        <template v-if="statusNum == 2">
          <img src="@/assets/img/fail.png" class="image_5" />
          <div class="flex-col items-center group_5">
            <span class="text_2">申请失败</span>
            <span class="text_3">非常抱歉，您的试用申请暂未通过</span>
          </div>
        </template>
      </div>
      <div class="flex-col section_2">
        <span class="text_4">申请信息</span>
        <div class="divider"></div>
        <div class="flex-col group_6">
          <div class="flex-col">
            <div class="flex-row">
              <span class="text_5">企业名称</span>
              <span class="text_6">{{ msg.enterpriseName }}</span>
            </div>
            <div class="flex-row group_9">
              <span class="text_7">联系人</span>
              <span class="text_8">{{ msg.contact }}</span>
            </div>
            <div class="flex-row group_10">
              <span class="text_9">联系电话</span>
              <span class="text_10">{{ msg.phone }}</span>
            </div>
          </div>
          <div v-if="statusNum == 2" class="flex-col group_11">
            <span class="text_11">失败原因：</span>
            <span class="text_11">{{ msg.denialReason }}</span>
            <span class="text_13">如果您对结果有任何异议，请联系：客户成功部，电话：0871-86077777</span>
          </div>
          <div v-else class="flex-col group_11">
            <span
              class="text_11">1.您的试用申请已通过，您可以凭申请时填写的联系电话登录企业管理平台和叮当智采小程序</span>
            <div class="section_3">
              <img class="img" src="https://item.qn.ddingddang.com/ddwebsite-qr_code-mini-program.png" alt />
            </div>
            <span class="text_12">叮当智采小程序二维码</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetails } from "@/api/api_library/trial";
export default {
  name: "trialStatus",
  data() {
    return {
      statusNum: null,
      msg: null,
      id: "",
    };
  },
  created() {
    this.id = this.$route.params.id
      ? this.$route.params.id
      : sessionStorage.getItem("trialId");
    this.getDetails(this.id);
  },
  mounted() { },
  methods: {
    getDetails(id) {
      getDetails(id)
        .then((res) => {
          this.msg = res.result;
          this.statusNum = this.msg.status;
        })
        .catch();
    },
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>
<style>
:root {
  --van-nav-bar-icon-color: black;
}
</style>
<style scoped lang="scss">
@import "../../assets/css/common.scss";

.page {
  background-color: rgb(255, 255, 255);
  width: 100%;
  overflow-y: auto;
  min-height: 100vh;

  .header {
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    width: 100%;

    .group_2 {
      color: rgb(50, 50, 51);
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      white-space: nowrap;

      .image_3 {
        align-self: center;
        width: 9px;
        height: 16px;
      }

      .text_1 {
        margin: 4px 0 2px;
        text-transform: uppercase;
      }

      .image_4 {
        margin-left: 45px;
        border-radius: 14px;
        width: 88px;
        height: 28px;
      }
    }
  }

  .group_4 {
    flex: 1 1 auto;
    overflow-y: auto;

    .section_1 {
      padding: 35px 0 66px;
      background-color: rgb(255, 255, 255);
      overflow: hidden;
      height: 225px;

      .image_5 {
        align-self: center;
        width: 60px;
        height: 60px;
      }

      .group_5 {
        margin-top: 16px;

        .text_2 {
          color: rgb(50, 50, 51);
          font-size: 18px;
          font-weight: 500;
          line-height: 25px;
          white-space: nowrap;
        }

        .text_3 {
          margin-top: 5px;
          color: rgb(150, 151, 153);
          font-size: 13px;
          line-height: 18px;
          white-space: nowrap;
        }
      }
    }

    .section_2 {
      padding: 14px 0 83px;
      background-color: rgb(255, 255, 255);
      border-top: 10px solid rgb(245, 245, 245);

      .text_4 {
        color: rgb(50, 50, 51);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        white-space: nowrap;
        text-align: left;
        padding: 0 16px;
      }

      .divider {
        margin-top: 11px;
        background-color: rgb(245, 247, 251);
        height: 1px;
      }

      .group_6 {
        margin-top: 13px;
        padding: 0 16px;

        .group_11 {
          margin-right: 3px;
          margin-top: 21px;

          .text_11 {
            color: rgb(150, 151, 153);
            font-size: 13px;
            line-height: 21px;
            text-align: left;
            word-wrap: break-word;
          }

          .text_13 {
            margin-right: 15px;
            margin-top: 8px;
            color: rgb(245, 107, 108);
            font-size: 13px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
          }

          .section_3 {
            margin-top: 15px;
            align-self: center;
            background-color: rgb(216, 216, 216);
            width: 120px;
            height: 120px;

            .img {
              width: 100%;
              height: 100%;
            }
          }

          .text_12 {
            margin-top: 7px;
            align-self: center;
            color: rgb(50, 50, 51);
            font-size: 14px;
            line-height: 20px;
            white-space: nowrap;
          }
        }

        .group_9 {
          margin-top: 10px;

          .text_7 {
            color: rgb(150, 151, 153);
            font-size: 14px;
            line-height: 20px;
            white-space: nowrap;
          }

          .text_8 {
            margin-left: 62px;
            color: rgb(50, 50, 51);
            font-size: 14px;
            line-height: 20px;
            white-space: nowrap;
            width: 70%;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .group_10 {
          margin-top: 10px;

          .text_9 {
            color: rgb(150, 151, 153);
            font-size: 14px;
            line-height: 20px;
            white-space: nowrap;
          }

          .text_10 {
            margin-left: 48px;
            color: rgb(50, 50, 51);
            font-size: 14px;
            line-height: 20px;
            white-space: nowrap;
          }
        }

        .text_5 {
          color: rgb(150, 151, 153);
          font-size: 14px;
          line-height: 20px;
          white-space: nowrap;
        }

        .text_6 {
          margin-left: 48px;
          color: rgb(50, 50, 51);
          font-size: 14px;
          line-height: 20px;
          white-space: nowrap;
          width: 70%;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
